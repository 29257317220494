import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HomeComponent} from './home/home.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../_shared/shared.module';
import {RouterModule, Routes} from '@angular/router';
import {SlickCarouselModule} from 'ngx-slick-carousel';

const MainRoutes: Routes = [
  // {path: 'learn', component: LearnComponent}
];

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    SlickCarouselModule,
    RouterModule.forChild(MainRoutes),
  ],
  exports: [
    TranslateModule
  ]
})
export class MainModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: MainModule,
      providers: []
    };
  }
}
