import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TruncateModule} from 'ng2-truncate';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatSelectModule,
    RouterModule,
    TranslateModule,
    TruncateModule,
    FormsModule,
  ],
  exports: [
    TranslateModule,
    FooterComponent,
    HeaderComponent,
  ]
})
export class SharedModule {
}
