import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'kriovault';

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    const lang = localStorage.lang = localStorage.lang ? localStorage.lang : 'en';
    this.translate.use(lang);
    this.translate.onLangChange.subscribe(event => {
      localStorage.lang = event.lang;
    });
  }
}
