import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

/*interface*/
export interface Languages {
  value: string;
  viewValue: string;
  src: any;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  languages: Languages[] = [
    {value: 'en', viewValue: 'EN', src: 'u-k.png'},
    {value: 'ger', viewValue: 'GER', src: 'germany.png'}
/*    {value: 'ru', viewValue: 'RU', src: 'russia.png'},
    {value: 'tur', viewValue: 'TUR', src: 'turkey.png'},
    {value: 'ch', viewValue: 'CH', src: 'china.png'},*/
  ];

  selected: string;
  img: string;
  toggle: any;
  checked: any;


  constructor(private translate: TranslateService) {
    this.toggle = true;
    this.checked = false;
  }

  ngOnInit() {
    this.selected = 'en';
    this.selected = this.translate.currentLang;
    this.img = '../../../../assets/images/flags/' + this.languages.find((lang) => lang.value === this.selected).src;
  }


  changeLang(e) {
    this.translate.use(e);
    this.img = '../../../../assets/images/flags/' + this.languages.find((lang) => lang.value === e).src;
    this.toggle = !this.toggle;
    this.checked = !this.checked;
  }

  menuToggle(event) {
    this.toggle = !this.toggle;
  }
}
