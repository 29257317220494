import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {HomeComponent} from './main/home/home.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent }
];


@NgModule({
  imports: [RouterModule.forRoot( routes,
    {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
      useHash: true
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
